<template>
  <v-container fluid>
    <v-card class="mt-2" v-for="bid in bids" :key="bid">
      <v-card-title v-text="`BID：${ bid }`"/>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="text-end text-lg-h5">設備ID</v-col>
          <v-col cols="10" class="text-start text-lg-h5">
            {{
              devices.filter(device => device.bid === bid).map(device => device.deviceId).join(',').toString()
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="text-end text-lg-h5">目前韌體版本</v-col>
          <v-col cols="10" class="text-start text-lg-h5">
            {{
              [...new Set(devices.filter(device => device.bid === bid).map(device => device.firmwareVersion))].join(',').toString()
            }}
          </v-col>
        </v-row>
        <v-row v-if="setting && setting?.updateFirmwareStarted!==0">
          <v-col cols="2" class="text-end text-lg-h5">目標升級版本</v-col>
          <v-col cols="10" class="text-start text-lg-h5">
            <span>{{ setting?.firmwareVersion }}</span>
          </v-col>
        </v-row>
        <v-row v-if="setting">
          <v-col cols="2" class="text-end text-lg-h5">升級狀態</v-col>
          <v-chip-group class="col p-1">
            <v-chip color="light-green accent-4" v-show="setting?.updateFirmwareStarted===0">已升級</v-chip>
            <v-chip color="purple accent-1" v-show="setting?.updateFirmwareStarted===1">待升級</v-chip>
            <v-chip color="deep-orange accent-2" v-show="setting?.updateFirmwareStarted===2">升級中</v-chip>
          </v-chip-group>
          <v-col cols="2" class="text-end" v-show="setting?.updateFirmwareStarted!==0">
            <v-btn color="error" @click="cancelUpgrade(devices[0].deviceId)">取消更新</v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-client-table
            :data="firmwares.filter(firmware=>firmware.bid === bid)"
            :columns="columns" :options="options">
          <template slot="action" slot-scope="{row}">
            <v-btn-toggle>
              <v-btn color="primary" @click="upgrade(row)" small>選擇升級此韌體</v-btn>
              <v-btn color="error" @click="deleteFirmware(row)" small>刪除</v-btn>
            </v-btn-toggle>
          </template>
        </v-client-table>
      </v-card-text>
    </v-card>
    <div class="mt-2 text-end">
      <v-btn color="primary" @click="showUpgrade=true">上傳韌體</v-btn>
    </div>
    <v-dialog
        max-width="600"
        :value="showUpgrade"
        @click:outside="showUpgrade=false">
      <v-card v-if="!!showUpgrade">
        <v-card-title>
          <p>升級</p>
          <v-spacer/>
          <v-btn icon @click="showUpgrade=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UploadFirmwareFrom @close="onUploadClos"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {deleteFirmware, getDeviceSetting, getFirmwares, upgradeDevice, cancelUpgradeDevice} from "@/Api";
import {mapActions} from "pinia";
import {toast, useLoadingStore} from "@/Store";
import UploadFirmwareFrom from "@/components/UploadFirmwareFrom";

export default {
  name: "DeviceUpgradeFrom",
  components: {UploadFirmwareFrom},
  props: {
    devices: Array
  },
  data() {
    return {
      bids: [],
      setting: undefined,
      firmwares: [],
      file: undefined,
      columns: ['name', 'version', 'description', 'action'],
      options: {
        headings: {
          name: '韌體名稱',
          version: '韌體版本',
          description: '韌體說明',
          action: '操作'
        },
        orderBy: {ascending: false, column: 'version'},
        sortable: [],
        filterable: false,
      },
      showUpgrade: false
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async loadSetting(deviceId) {
      this.showLoading()
      this.setting = await getDeviceSetting(deviceId)
      this.dismissLoading()
    },
    async upgrade(firmware) {
      this.showLoading()
      let deviceId = this.devices.filter(device => device.bid === firmware.bid)
          .map(device => device.deviceId)

      let group = []
      deviceId.forEach((id, index) => {
        if (index % 200 === 0) {
          group.push([])
        }
        group[group.length - 1].push(id)
      })
      for (const array of group) {
        let response = await upgradeDevice(array, firmware.id)
        if (response.ok) {
          if (deviceId.length === 1) {
            await this.loadSetting(deviceId[0])
          }
        } else {
          this.error(response.message)
        }
      }
      this.dismissLoading()
    },
    async cancelUpgrade(deviceId) {
      this.showLoading()
      let response = await cancelUpgradeDevice(deviceId)
      if (response.ok) {
        await this.loadSetting(deviceId)
      } else {
        this.error(response.message)
      }
      this.dismissLoading()
    },
    async deleteFirmware(firmware) {
      this.showLoading()
      await deleteFirmware(firmware.id)
      this.firmwares = await getFirmwares()
      this.dismissLoading()
    },
    onUploadClos(firmware) {
      this.showUpgrade = false
      if (firmware) {
        this.firmwares = [firmware, ...this.firmwares]
      }
    }
  },
  async mounted() {
    let bids = new Set()
    this.devices.forEach(device => bids.add(device.bid))
    this.bids = bids
    this.firmwares = await getFirmwares([...bids])
    if (this.devices.length === 1) {
      await this.loadSetting(this.devices[0].deviceId)
    }
  }
}
</script>

<style scoped>

</style>